.forward {

    .heart-logo {
        max-width: 123px;
        margin: auto;
        position: relative;
        top: -50px;
    }

    padding-bottom: 0px;

    .top-wrap {
        // padding-top: 100px;
        // border: none;
        background: #F6FAFD;
        box-shadow: 5px 0px 80px rgba(138, 149, 158, 0.1);
        width: 90%;
        @include media-breakpoint-up(md) {
            width: 100%;
            // padding-top: 100px;
        }
    }
    .top-block{
        .container{
            background: #f6fafd;

        }
    }
    h3 {
        font-weight: 700;
        font-size: 2.6rem;
        line-height: 1.2;
        letter-spacing: 0.02em;
        color: #03244C;
    }

    .bottom-block {
        background-color: #03244C;

        .bottom-wrap {
            background: #F6FAFD;
            // border: none;
            width: 90%;
            @include media-breakpoint-up(md) {
                width: 100%;
            }
        }

    }

    .refund-img {
        img {
            max-width: 204.27px;
        }
    }
    .ready-text{
        // padding-top: 40px;
        position: relative;
        img{
            position: absolute;
            width: 65px;
            top: -60px;
            right: 0;

            @include media-breakpoint-up(lg) {
                position: absolute;
                right: 50px;
                top: -100px;
                width: 140px;
            
            }
        }
    } 
    .tc {
        text-decoration: none;
    }
    .tc:hover {
        color: rgb(194, 193, 193);
        text-decoration: underline;
    }
}