footer {
    overflow: auto;

    .pre-copy {
        background: #03244C;

        p {
            font-size: 2rem;
            line-height: 1.1;
            color: #fff;

            a {
                text-decoration: none;
                color: #fff;
                transition: 0.3s;

                &:hover {
                    color: #fff;
                    text-decoration: underline;
                }
            }
        }

        .logo {
            img {
                max-width: 190px;

                @include media-breakpoint-up(md) {
                    max-width: 270px;

                }

            }
        }
    }

    .post-copy {
        background: #fff;

        p {
            font-size: 13px;
            color: #000;
        }
    }
}