.four-steps {
    .list-item {
        background: #F6FAFD;
        margin-bottom: 20px;
        padding: 30px;
        @include media-breakpoint-up(md) {
            padding: 0px;

        }
    }

    .icon {}

    .header {
        max-width: 560px;
        padding-top: 50px;
        @include media-breakpoint-up(md) {
            padding-top: 80px;
        }
    }

    .custom-list {
        padding-top: 35px;
        max-width: 824px;
        margin: auto;

        .list-wrapper {
            align-items: center;
            display: flex;
            padding-bottom: 15px;

            @include media-breakpoint-up(md) {
                padding: 40px 30px;
            }

            .list-copy {
                padding-left: 20px;

                @include media-breakpoint-up(md) {

                    padding-left: 30px;
                }
            }

            .heading {
                font-weight: 700;
                color: #000;

                @include media-breakpoint-up(md) {
                    letter-spacing: -0.02em;
                    padding-bottom: 10px;
                }
            }

        }

        .copy-mob {
            // padding: 15px 0 30px 0;
            @include media-breakpoint-up(lg) {
                max-width: 300px;
            }
        }
    }

}