// --------------------
// VARIABLES
//---------------------

// Colors
$body-color				: #333333;
$primary        		: #333333;
$secondary      		: #03244C;

// color: #03244C;

// $success:       $green;
// $info:          $cyan;
// $warning:       $yellow;
// $danger:        $red;
// $light:         $gray-100;
// $dark:          $gray-800;

// Header
$headerheight   		: 150px;  // Change to approximate header height - adds padding to first section
$header-starting-width	: 250px;
$header-scrolled-width	: 120px;
$headerbg       		: #fff; // Header scrolled background color.

// Tables
$bg-table-active-color 	: #42c7b3;

// Slider
$slider-title-color 	: #ffffff;
$slick-dot-color 		: #ffffff;
$slick-active-dot-color : #42c7b3;

// Grid settings
$grid-gutter-width		: 3rem !default;
