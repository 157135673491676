// --------------------
// BUTTON STYLE
//---------------------

.btn,
input[type="submit"]:not(.search-submit),
input[type="button"] {
    position: relative;
    z-index: 2;
    overflow: hidden;
    width: 100%;
    border: 0;
    border-radius: 0;
    margin-bottom: 0;
    font-weight: 700;
    line-height: 1;
    text-transform: uppercase;
    padding: 20px 10px;
    white-space: normal;
    background: rgba(111, 209, 222, 1);
    // text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

    // font-size: 1.6rem;
    font-size: 2rem;
    @include media-breakpoint-up(md) {
        font-size: 2rem;
    }

    &::before {
        content: "";
        display: block;
        clip-path: polygon(20% 0, 100% 0%, 100% 100%, 0% 100%);
        width: 50%;
        height: 100%;
        background: rgba(108, 198, 210, 1);
        position: absolute;
        right: 0;
        top: 0;
        z-index: -1;
        transition: width 0.35s ease, clip-path 0.3s ease;
    }

    color:rgba(3, 36, 76, 1);

    display: block;
    transition: color 0.1s ease,
    // text-shadow 0.3s ease,
    background-color 0.1s ease;

    @include media-breakpoint-up(md) {
        padding: 30px;
    }

    &:hover,
    &:focus,
    &:active {
        color:rgba(3, 36, 76, 1);

        background: rgba(108, 198, 210, 1);
        // text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.6);

        &:before {
            width: 100%;
            clip-path: polygon(120% 0, 100% 0%, 100% 100%, 100% 100%);

        }
    }

    &.previous {
        background-color: white;
        color: $primary;
        text-shadow: none;

        &:before {
            display: none;
        }

        &:hover,
        &:focus,
        &:active {
            background-color: $primary !important;
            text-shadow: none !important;
            color: white !important;

        }
    }
}

.btn-section {
    p {
        width: 90%;
        margin: 0 auto;
        font-size: 1.9rem;
    }

    text-align: center;

    .btn {
        max-width: 523px;
        width: 100%;
        margin: 0 auto;
        padding-left: 35px;
        padding-right: 35px;
        &.ebook-btn {
            margin: 0;
        }
    }

    .btn+p {
        width: 50ch;
        max-width: 100%;
        margin-top: 1.3rem;
    }
}
.submit-wrapper {
    margin-bottom: 10px;

    .submit-inner {
        position: relative;
    }

    .btn-load {
        position: absolute;
        background: linear-gradient(180deg, #E6B228 0%, #B6932C 100%);
        opacity: 1;
        &:hover {
            opacity: 0;
        }
        padding: 10px 40px;
        @include media-breakpoint-up(sm) {
            padding: 20px 120px;
        }
        @include media-breakpoint-up(md) {
           padding: 20px 100px;
        }
        @include media-breakpoint-up(lg) {
            padding: 20px 50px;
        }
        @include media-breakpoint-up(xl) {
           padding: 20px 70px;
        }
    }

    .btn-active {
        position: absolute;
        opacity: 0;
        // background: linear-gradient(180deg, #c02e1b 0%, #cc2d1e 100%);
        background: #8DC63F;

        &:hover {
            opacity: 1;
        }
        padding: 10px 40px;
        @include media-breakpoint-up(sm) {
            padding: 20px 120px;
        }
        @include media-breakpoint-up(md) {
           padding: 20px 100px;
        }
        @include media-breakpoint-up(lg) {
            padding: 20px 50px;
        }
        @include media-breakpoint-up(xl) {
           padding: 20px 70px;
        }
    }
}
