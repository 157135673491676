.testimonials {
    background: #F6FAFD;

    .stars-head {
        max-width: 220px;
    }

    .review-wrapper {
        padding: 30px 18px;
        margin-bottom: 50px;

        @include media-breakpoint-up(md) {
            padding: 50px 40px 100px 40px;
            margin-bottom: 0px;
        }

        background: #FFFFFF;
        box-shadow: 0px 5.10037px 63.7546px rgba(19, 79, 105, 0.1);
        border-radius: 5px;
        // min-height: 400px;
        font-size: 1.8rem;
        line-height: 1.5;
        letter-spacing: 0.02em;
        color: #333333;
        // .quote {
        //     max-width: 40px;
        // }

        // .review-copy {

        // }
        // .name{

        // }
    }

    .nav-btns {
        margin: 30px 0;
    }

    .review-button-next,
    .review-button-prev {
        margin: 0 10px;
        justify-content: normal;
        position: relative;

        // @include media-breakpoint-up(md) {
        //     position: absolute;
        // }


    }

    .swiper {
        overflow: hidden;

        @include media-breakpoint-up(md) {
            overflow: inherit;
        }
    }

    .review-button-next {
        left: 0;
        right: 0;

        // @include media-breakpoint-up(md) {
        //     left: auto;
        //     right: 10px;
        //     top: 50%;
        // }
    }

    .review-button-prev {
        left: 0;
        right: 0;

        // @include media-breakpoint-up(md) {
        //     top: 50%;
        //     left: 10px;
        //     right: auto;
        // }
    }

    .review-button-next::after,
    .review-button-prev::after {
        font-size: 0 !important;
    }

    .quote-wrap {

        position: relative;

        .icon {
            position: absolute;
            top: -5px;
            width: 80px;
            @include media-breakpoint-up(md) {

                left: -20px;
                top: -25px;
                width: 150px;
            }
        }

        .copy {
            padding: 20px 0px 20px 30px;
            background: #FFFFFF;
            font-family: 'Raleway';
            font-weight: 700;
            line-height: 1.1;
            letter-spacing: 0.02em;
            font-size: 1.8rem;
            color: #333333;
            margin-left: 40px;

            @include media-breakpoint-up(md) {
                font-size: 2rem;
                padding: 30px 30px 30px 100px;
            }

        }
    }
}