.telehealth-leader {
    .quote-wrap {

        position: relative;

        .icon {
            position: absolute;
            top: -5px;
            width: 80px;

            @include media-breakpoint-up(md) {

                left: -20px;
                top: -25px;
                width: 150px;
            }
        }

        .copy {
            padding: 20px 0px 20px 30px;
            background: #FFFFFF;
            font-family: 'Raleway';
            font-weight: 700;
            line-height: 1.1;
            letter-spacing: 0.02em;
            font-size: 1.8rem;
            color: #333333;
            margin-left: 40px;

            @include media-breakpoint-up(md) {
                font-size: 2rem;
                padding: 30px 30px 30px 100px;
            }

        }
    }

    .banner-wrapper {
        .center-img {}

    }

    .right-texts {
        position: relative;
        right: 0;

    }

    .left-texts {
        position: relative;
        left: 0;

    }

    .right-texts {
        .text-bub {
            position: relative;

            &:nth-child(1) {
                top: -2px;
                left: 45px;

                @include media-breakpoint-up(md) {

                    top: -21px;
                    left: 105px;
                }
            }

            &:nth-child(2) {
                left: -44px;
                top: 7px;

                @include media-breakpoint-up(md) {
                    left: 57px;
                }
            }

            &:nth-child(3) {
                bottom: -25px;
                left: 86px;
            }
        }
    }

    .left-texts {
        .text-bub {
            position: relative;

            &:nth-child(1) {
                right: 23px;
                top: -20px;

                @include media-breakpoint-up(md) {
                    right: 47px;
                    top: -11px;
                }
            }

            &:nth-child(2) {
                right: -66px;
                top: -4px;

                @include media-breakpoint-up(md) {
                    right: 26px;
                    top: -1px;
                }
            }

            &:nth-child(3) {
                right: 0;
                top: 12px;
            }

            &:nth-child(4) {
                right: 36px;
                top: 25px;
            }
        }
    }

    .hear-text {
        @include media-breakpoint-up(md) {
            padding-top: 40px;
        }

        position: relative;

        img {
            position: absolute;

            right: 15px;
            top: -32px;
            width: 100px;

            @include media-breakpoint-up(md) {
                right: 15px;
            }

            @include media-breakpoint-up(lg) {
                right: 50px;
                top: -32px;
                width: 140px;

            }

            @include media-breakpoint-up(xl) {

                right: 20px;
                top: -63px;
            }
        }
    }
}