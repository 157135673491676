.offer{
    h2{
        color: #03244C;
    }
    padding-bottom: 60px;
    .styled-list{
        p, strong{
            font-family: $body-font;
        }
        .tick {
            margin-right: 1rem;
        @include media-breakpoint-up(md) {
            max-width: 20px;
            margin-right: 2rem;
            margin-top: .5rem;
        }
        }
    }

    .btn{
        padding: 10px 60px;
        @include media-breakpoint-up(md) {
            padding: 25px 60px;
        }
    }

    .offer-banner{
        max-width: 1060px;
    }
    .eyebrow{
        font-weight: 700;
        color: #1267B1;
    }
        .btn-section {
            .btn{
                max-width:587px;
                padding: 30px !important;
            }
        }
}

