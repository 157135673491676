.prof-dev {
    .trusted {
        font-weight: 600;
        font-size: 2rem;
        line-height: 1.5;
        letter-spacing: 0.02em;
        color: #03244C;
    }

    .step-blocks {
        .step-wrapper {
            position: relative;
            background: rgba(246, 250, 253, 1);
            padding: 30px 20px 60px 20px;
            min-height: 334px;
            
            @include media-breakpoint-up(lg) {
                min-height: 441px;
                padding: 40px 20px 0px 40px;
            }

            h5 {
                z-index: 1;
                position: relative;
                // font-size: 1.8rem;
                font-size: 2.2rem;
                @include media-breakpoint-up(lg) {
                    font-size: 2rem;
                }
            }

            p {
                position: relative;
                z-index: 1;
                padding-right: 80px;
                line-height: 1.2;
                letter-spacing: 0.02em;
                color: #333333;
                font-size: 2.2rem;

                @include media-breakpoint-up(lg) {
                    font-size: 2rem;
                    padding-right: 0px;
                }
            }

            .num {
                font-size: 190.032px;
                z-index: 0;
                @include media-breakpoint-up(lg) {
                    font-size: 249.66px;
                }
                color: #E7F5FF;
                font-weight: 700;
                position: absolute;
                line-height: 1;
                right: -7px;

               
                &.one {
                    bottom: -27px;
                    @include media-breakpoint-up(md) {
                        bottom: -37px;
                    }
                }

                &.two {
                    bottom: -28px;
                    @include media-breakpoint-up(md) {
                        bottom: -37px;
                        right: -11px;
                    }
                }
                &.three {
                    bottom: 0px;
                }

                &.four {
                    bottom: -6px;
                }
             
            }

        }
        .bg-img {
            background: none;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
            height: 100%;
            padding: 0;
        }
    }
}