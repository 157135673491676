body.modal-open {
    height: 100vh;
    overflow-y: hidden;

    .modal {
        background: rgba(0, 0, 0, .8);

        .modal-dialog {

            // max-width: 90%;
            @include media-breakpoint-up(md) {
                // max-width: 700px;
            }
        }

        .modal-dialog,
        .modal-content {
            // max-height: 80%;
			
			// width: 90%;
			margin: auto;
            @include media-breakpoint-up(md) {
				// width: 600px;
				max-width: 582px;
                // padding: 40px 20px 20px 20px;
            }

            .modal-header {
                border-bottom: none !important;
                font-family: $headings-font;
                font-weight: 700;
				font-size: 3rem;

                color: #053333;
				padding: 20px 20px 0 20px;
                @include media-breakpoint-up(md) {
					font-size: 3.4rem;
					padding: 50px 50px 0 50px;
                }


            }
        }

        .modal-body {
			p{
				color: #333333;
			}
            // overflow-y: scroll;
				padding: 20px;

			@include media-breakpoint-up(md) {
				padding: 20px 50px 50px 50px;
			}
			.form-container{
				.btn-section{
					.btn{
						padding: 20px 10px;
						// max-height: 63px;
						font-size: 2rem;
					}
				}
			}
        }

        .close {
            right: 0;
        }
    }
    form {
        input[type=text],
        input[type=email]{

            // box-shadow: 1px 1px 10px rgba(0,0,0,0.1);
            box-shadow: 30px 26px 40px -20px rgba(153, 153, 153, 0.08);

            color: #505050;
            font-size: 1.8rem;

            &:focus-visible{
                border: 0.5px solid rgba(0, 0, 0, 0.15);
                // box-shadow: 0px 15px 30px rgba(45, 57, 19, 0.12);
            }
            &:focus, input:focus{
                outline: none;
            }
        }
    }

}