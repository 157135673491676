form {

    input[type=text],
    input[type=email],
    input[type=tel],
    textarea,
    select {
        width: 100%;
        margin-bottom: 15px;
        border-radius: 0;
        line-height: 1.1;
        background: #F6FAFD;

        color: #000 !important;
        border: none;

        &::placeholder {
            color: #000;
            opacity: 1;
        }

        padding: 20px 15px 20px 45px;
        font-size: 2rem;

        @include media-breakpoint-up(md) {
            padding: 25px 15px 25px 80px;
        }

        &:-internal-autofill-selected {
            background-color: $white !important;
            background-image: none !important;
        }
    }

    input[type="submit"] {
        width: 100%;
    }

    textarea {
        height: 210px !important;
    }

    select {

        color: #000 !important;
        padding: 20px 15px 20px 45px !important;
        background: #F6FAFD;
        font-size: 2rem;

        @include media-breakpoint-up(md) {
        background-size: 15px auto;

            padding: 25px 15px 25px 80px !important;
        }

        appearance: none;
        background-image: url("data:image/svg+xml,%3Csvg width='24' height='14' viewBox='0 0 24 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2 2L12 11L22 2' stroke='%23272727' stroke-width='3' stroke-linecap='round'/%3E%3C/svg%3E%0A");
        background-repeat: no-repeat;
        background-position: right 4rem center;
        background-size: 20px auto;

        overflow: hidden;
        white-space: pre;
        text-overflow: ellipsis;
        line-height: 1.4;
        // @include media-breakpoint-up(md) {
        //     padding: 17px 15px 20px 70px;
        // }
    }



    .form-group {
        position: relative;
        width: 100%;
        margin-bottom: 0;
        line-height: 1;

        .icon {
            position: absolute;
            top: 20px;
            left: 15px;
            width: 15px;
            height: 15px;
            z-index: 999;

            @include media-breakpoint-up(md) {
                left: 25px;
                width: 25px;
                height: 25px;
            }

            &.icon-message {
                top: 23px;
            }
        }
       
    }

    label.error,
    .field-error {
        display: block;
        margin-bottom: 20px;
        margin-top: -10px;
        color: red;
        font-size: 1.5rem;
    }

    .form-messages {
        text-align: center;
        // padding-top: 30px;

        &.success {
            color: green;
        }

        &.error {
            color: red;
        }
    }
}