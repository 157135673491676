body.ebook-page {
    .eyebrow {
        text-align: center;

        @include media-breakpoint-up(lg) {
            text-align: left;
        }
    }

    .ebook-header {

        background: #f3f4f5;
        padding-top: 80px;
        padding-bottom: 60px;

        @include media-breakpoint-up(md) {
            padding-top: 120px;
            padding-bottom: 80px;

        }

        @include media-breakpoint-up(lg) {
            padding-top: 100px;
            padding-bottom: 40px;
        }

        @include media-breakpoint-up(xl) {
            padding-top: 80px;
            padding-bottom: 40px;
        }

        .header-logo {
            img {
                width: 120px;

                @include media-breakpoint-up(md) {
                    width: 250px;
                }
            }
        }

        // .ebook-header-logo {
        //     width: 120px;
        //     margin: auto;
        //     padding-bottom: 30px;

        //     @include media-breakpoint-up(lg) {
        //         width: 200px;
        //         margin: 0;
        //     }

        //     @include media-breakpoint-up(lg) {
        //         width: 270px;
        //         padding-bottom: 60px;
        //     }
        // }
    }

    .logos {
        padding: 30px 0px 0px 0px;

        @include media-breakpoint-up(md) {
            padding: 60px 0px 0px 0px;
        }
    }

    .styled-list {
        .tick {
            max-width: 32px;
            margin-right: 1rem;
            margin-top: .5rem;
        }
    }

    .ebook-instant {
        h2 {
            text-transform: none;
        }

        background: rgba(3, 36, 76, 1);

        .stamp-img {
            max-width: 660px;
        }
    }

    footer {

        .pre {
            background: #fff;

            p {
                font-size: 2rem;
                line-height: 1.1;
                color: #333;

                a {
                    text-decoration: none;
                    color: #333333;
                    transition: 0.3s;

                    &:hover {
                        color: #333333;
                        text-decoration: underline;
                    }
                }
            }

            .logo {
                img {
                    max-width: 190px;

                    @include media-breakpoint-up(md) {
                        max-width: 270px;
                    }
                }
            }
        }

    }

}