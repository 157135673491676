.intro {
    padding-bottom: 0px;
    background-color: #fff;

    .top-wrap {
        padding-top: 40px;
        // border: none;
        box-shadow: 5px 0px 80px rgba(138, 149, 158, 0.1);
        width: 87%;

        @include media-breakpoint-up(md) {
            width: 100%;
            padding-top: 100px;
        }
    }

    .bottom-block {
        background-color: #03244C;

        .bottom-wrap {
            background: #fff;
            border: none;
            width: 87%;

            @include media-breakpoint-up(md) {
                width: 100%;
            }
        }

        .notes {
            position: relative;

            .bling-dt {
                position: absolute;
                right: -12px;
                top: -38px;
                width: 85px;

                @include media-breakpoint-up(md) {
                    width: 85px;
                    right: -52px;
                    top: -38px;
                }
            }

            .bling-mb {
                position: absolute;
                left: 170px;
                top: -18px;
                width: 45px;

            }
        }
    }

    .intro-banner {
        .bg-img {
            // position: absolute;
            transform: scale(1.2);
            left: -35px !important;
            position: relative;

            @include media-breakpoint-up(sm) {
                left: 0 !important;
                transform: scale(1);

            }
        }

        .overlay-img {
            position: absolute;
            transform: scale(1.2);
            left: -35px !important;

            @include media-breakpoint-up(sm) {
                left: 0 !important;
                transform: scale(1);

            }

            //                 transform: scale(1.3);
            // left: -27px !important;
        }

        .icon {
            width: 12rem;
            left: 1%;
            top: 21%;

            @include media-breakpoint-up(sm) {
                width: 22rem;
                top: 24%;
                left: 8%;
            }
        }
    }


    .stat-wrap {

        .stat {
            padding: 3px;

            @include media-breakpoint-up(md) {

                padding: 10px 15px;
            }

            position: absolute;
            background: #fff;
            box-shadow: 0px 6px 8px rgba(46, 49, 52, 0.15);

            .stat-icon {

                border-right: .5px solid #a6a6a6;
                padding: 0 5px 0 5px;

                @include media-breakpoint-up(md) {
                    padding: 8px 15px 8px 0;
                }

                img {
                    height: 2rem;
                    @include media-breakpoint-up(sm) {
                        height: 2.3rem;
                    }
                }
            }

            .copy {
                line-height: 1.1;
                letter-spacing: 0.02em;
                color: #333333;
                font-size: 1.8rem;
                padding-left: 7px;

                @include media-breakpoint-up(md) {
                    padding-left: 15px;
                    font-size: 2rem;
                }
            }

            &:nth-child(1) {
                right: 1rem;
                top: 3rem;

                @include media-breakpoint-up(sm) {
                    right: 5rem;
                    top: 7rem;
                }

                @include media-breakpoint-up(md) {
                    right: -4rem;
                    top: 7rem;
                }
            }

            &:nth-child(2) {
                right: 1rem;
                top: 11rem;

                @include media-breakpoint-up(sm) {
                    right: 5rem;
                    top: 17rem;
                }

                @include media-breakpoint-up(md) {
                    right: -6rem;
                    top: 17rem;
                }
            }

            &:nth-child(3) {
                right: 1rem;
                top: 19rem;

                @include media-breakpoint-up(sm) {

                    right: 5rem;
                    top: 28rem;
                }

                @include media-breakpoint-up(md) {
                    right: -5rem;
                    top: 27rem;
                }
            }
        }
    }

    // }

}