.sharpen {
    image-rendering: -webkit-optimize-contrast;
}

.hero-centered {
    padding: 80px 0 50px 0;

    @include media-breakpoint-up(md) {
        padding: 160px 0px 80px 0px;
    }

    background: #F3F4F5;
    // background: radial-gradient(50% 50% at 50% 50%, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%);

    h1 {
        color: #03244C;
        max-width: 1050px;
    }

    .subheadline {
        color: #3A3A3A;
        max-width: 972px;

    }

}
.video-col{
    max-width: 1018px;
}
.red-underline {
    background-image: url(../svgs/red-underline.svg);
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: 100%;
    margin: 0 -.2em;
    padding: 0 .2em;
    position: relative;

    &.pb5 {
        padding-bottom: 8px;
    }
}

.org-underline {
    background-image: url(../svgs/orange-underline.svg);
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: 100%;
    margin: 0 -.2em;
    padding: 0 .2em;
    position: relative;
    padding-bottom: 1px;

    &.pb5 {
        padding-bottom: 5px;
    }
    &.buff{
        background-size: 130%;
    }
}
.red-outline{

    background-image: url("data:image/svg+xml,%3Csvg width='647' height='151' viewBox='0 0 647 151' fill='none' preserveAspectRatio='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M54.7496 18.4072C-15.3826 62.4344 -60.6424 148.573 319.375 140.908C794.398 131.326 719.93 -5.71945 288.187 16.7458C220.761 19.3501 68.922 29.9943 0.968706 51.7368' stroke='%23EE6C4D' stroke-width='2'/%3E%3C/svg%3E%0A");
    background-size: 100% 100%;
    margin:  1rem  0rem 5rem 0rem;
    padding: 7rem 2rem 6rem 5rem;
    line-height: 1.5;
    
    @include media-breakpoint-up(md) {
        padding: 5rem 3rem 4rem 8rem;
        margin:  1rem  -3rem 5rem -8rem;
        line-height: 1.15;
    }
}

.red-outline-sml{

    background-image: url("data:image/svg+xml,%3Csvg width='647' height='151' viewBox='0 0 647 151' fill='none'  preserveAspectRatio='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M54.7496 18.4072C-15.3826 62.4344 -60.6424 148.573 319.375 140.908C794.398 131.326 719.93 -5.71945 288.187 16.7458C220.761 19.3501 68.922 29.9943 0.968706 51.7368' stroke='%23EE6C4D' stroke-width='2'/%3E%3C/svg%3E%0A");
    background-size: 100% 100%;
    line-height: 1.5;

    width: 95px;

    @include media-breakpoint-up(md) {
        width: 120px;
    }
    padding: 10px;
        
    // margin: -1rem 25rem 1rem -1rem;
    // padding: 2rem 0 2rem 1rem;

    // @media screen and (min-width: 360px) {
    //     margin: -1rem 28rem 1rem -1rem;
    //     padding: 2rem 0rem 2rem 1rem;
    // }

    // @media screen and (min-width: 414px) {
    //     margin: -1rem 34rem 1rem -1rem;
    //     padding: 2rem 0rem 2rem 1rem;
    // }
    
    // @media screen and (min-width: 455px) {
    //     margin: -1rem 38rem 1rem -1rem;
    //     padding: 2rem 0rem 2rem 1rem;
    // }

    // @media screen and (min-width: 480px) {
    //     margin: -1rem 43rem 1rem -1rem;
    //     padding: 2rem 0rem 2rem 1rem;
    // }

    // @media screen and (min-width: 535px) {
    //     margin: -1rem 47rem 1rem -1rem;
    //     padding: 2rem 0rem 2rem 1rem;
    // }

    // @include media-breakpoint-up(sm) {
    //     margin: -1rem 47rem 1rem -1rem;
    //     padding: 2rem 0rem 2rem 1rem;
    // }
    // @include media-breakpoint-up(md) {
    //     margin: -2rem 52rem 1rem -2rem;
    //     padding: 2rem 0rem 2rem 2rem;
    // }
    // @include media-breakpoint-up(lg) {
    //     margin: -2rem 50rem 1rem -2rem;
    //     padding: 2rem 0rem 2rem 2rem;
        
    // }
    // @include media-breakpoint-up(xl) {
    //     margin: -2rem 50rem 1rem -2rem;
    //     padding: 2rem 0rem 2rem 2rem;
    // }
}



// .sml-bg-circle {
//     position: relative;

//     img {
//         height: 53px;
//         left: -12px;
//         overflow: visible;
//         position: absolute;
//         top: -12px;
//         width: 117px;
//         z-index: -1;

//     }
// }

.styled-list {
    .tick {
        max-width: 20px;
        margin-right: 2rem;
        margin-top: .5rem;
    }
}

.usp-notes {
    .icon {
        margin-right: 15px;

        @include media-breakpoint-up(lg) {

            margin-right: 25px;
        }

        width: 20%;
    }

    .text {
        width: 80%;
    }
}