.clinicians{
    padding: 60px 0px;
    @include media-breakpoint-up(md) {
        padding: 80px 0 0 0;
    }
    h2{
    margin-bottom: 0;
    @include media-breakpoint-up(md) {
        margin-bottom: -50px;
    }
    }
}