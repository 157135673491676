.hands-on {
    background: rgba(3, 36, 76, 1);
    .usp-wrap {
        .icon {
            width: 20%;
            img {
                max-width: 30px;
                @include media-breakpoint-up(md) {
                    max-width: 60px;
                }
            }
        }

        .text {
            width: 70%;
            padding-left: 10px;
            font-weight: 700;
            font-size: 1.6rem;
            // font-size: 1.7rem;
            @include media-breakpoint-up(lg) {
                font-size: 2rem;
                padding-left: 20px;

            }
        }

    }

    .usp-item {
        position: relative;
        padding: 20px;
        @include media-breakpoint-up(lg) {
            padding: 35px;
        }
    }

    .usp-item:before {
        content: "";
        height: 70%;
        position: absolute;
        right: 0;
        width: 50%;
        border-right: 1px solid rgba(255, 255, 255, .3)
    }

    .usp-item:after {
        content: "";
        border-bottom: 1px solid rgba(255, 255, 255, 0.3);
        height: 100%;
        position: absolute;
        right: 10%;
        width: 70%;
    }



    .usp-item:nth-child(5),
    .usp-item:nth-child(6),
    .usp-item:nth-child(7),
    .usp-item:nth-child(8) {
        &:after {
            display: block;

            @include media-breakpoint-up(md) {
                display: none;
            }
        }
    }

    .usp-item:nth-child(7),
    .usp-item:nth-child(8) {
        &:after {
            display: none;

        }
    }


    .usp-item:nth-child(4),
    .usp-item:nth-child(8) {
        &:before {
            display: none;
        }
    }

    .usp-item:nth-child(2),
    .usp-item:nth-child(6) {
        &:before {
            display: none;

            @include media-breakpoint-up(md) {
                display: block;
            }
        }
    }


}