// --------------------
// Include Fonts Here
// --------------------
// @font-face {
//     font-family: 'Averta';
//     src: url('../fonts/Averta-Regular.woff2') format('woff2'),
//     url('../fonts/Averta-Regular.woff') format('woff');
//     font-weight: normal;
//     font-style: normal;
// }

// @font-face {
//     font-family: 'Averta';
//     src: url('../fonts/Averta-Bold.woff2') format('woff2'),
//     url('../fonts/Averta-Bold.woff') format('woff');
//     font-weight: bold;
//     font-style: normal;
// }


@font-face {
    font-family: 'Raleway';
    src: url('../fonts/Raleway-Bold.woff2') format('woff2'),
        url('../fonts/Raleway-Bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Raleway';
    src: url('../fonts/Raleway-SemiBold.woff2') format('woff2'),
        url('../fonts/Raleway-SemiBold.woff') format('woff');
    font-weight: 400;
    font-style: normal;
}


@font-face {
    font-family: 'Raleway';
    src: url('../fonts/Raleway-Regular.woff2') format('woff2'),
        url('../fonts/Raleway-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Oswald';
    src: url('../fonts/Oswald-SemiBold.woff2') format('woff2'),
        url('../fonts/Oswald-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Sticky Notes';
    src: url('../fonts/Sticky-Notes.woff2') format('woff2'),
        url('../fonts/Sticky-Notes.woff') format('woff');
    font-weight: 400;
    font-style: normal;
}



$headings-font: 'Oswald', sans-serif;
$body-font: 'Raleway', sans-serif;

// --------------------
//  Include Basic Typography
// --------------------

:root {
    font-size: 50%; //1rem = 8px

    @include media-breakpoint-up(md) {
        font-size: 56.25%; //1rem = 9px
    }

    @include media-breakpoint-up(lg) {
        font-size: 62.5%; // 1rem = 10px
    }
}

html,
body {
    font-family: $body-font;
    @include font-smoothing('on');
    line-height: 1.5;
    font-size: 2rem;
    @include media-breakpoint-up(md) {
        font-size: 2.3rem; 
        line-height: 1.3;
    }

}

p {
    margin-bottom: 1.6rem;
}

// Headline padding
h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
    line-height: 1.3;
    margin-bottom: 2rem;
    font-family: $headings-font;
    font-weight: bold;
    text-transform: capitalize;
}

h1,
.h1 {
    line-height: 1.2;
    text-align: center;
    // letter-spacing: 0.02em;
    font-size: 3.7rem;
    @include media-breakpoint-up(md) {
        font-size: 5.3rem;
    }
}

h2,
.h2 {
    font-size: 4.6rem; //Laptop: 46px, Ipads: 41.4px, Mobiles: 36.8px
    font-weight: 600;
    line-height: 1.2;
    text-align: center;
    color: $secondary;
    font-size: 3.3rem;

    @include media-breakpoint-up(md) {
        font-size: 4.3rem;
    }

    // &::before {
    //     content: '';
    //     position: absolute;
    //     top: 0;
    //     left: calc(50% - 40px);
    //     width: 80px;
    //     height: 1px;
    //     background: $primary;
    // }
    // @include media-breakpoint-up(lg) {
    //     &.text-lg-left:before {
    //         left: 0;
    //     }
    // }
    // &.text-white:before {
    //     background: white;
    // }
}

h3,
.h3 {
    font-family: $body-font;
    // font-size: 1.8rem;
    font-size: 2rem;
    line-height: 1.25;
    letter-spacing: 0.02em;
    font-weight: 700;

    @include media-breakpoint-up(md) {
    }
}

h4,
.h4 {
    font-size: 1.6rem;
    line-height: 1.2;
    letter-spacing: 0.02em;
    font-family: $body-font;
    color: #EE6C4D;
    // @include media-breakpoint-up(md) {
    //     font-size: 1.6rem;
    // }
}

h5,
.h5 {
    font-weight: 700;
    font-size: 1.8rem;
    line-height: 1.18;
    letter-spacing: 0.02em;
    font-family: $body-font;

    @include media-breakpoint-up(md) {
        font-size: 2.2rem;

    }

}

h6,
.h6 {}

strong {
   font-weight: 700;
}

.eyebrow {
    text-transform: uppercase;
    font-family: $body-font;
    font-weight: 700;
    color: #EE6C4D;
    line-height: 1.2;
    letter-spacing: 0.1em;
    font-size: 1.8rem;

    @include media-breakpoint-up(md) {
        font-size: 2rem;
    }
}

.subheadline {
    font-size: 2.5rem;
    font-family: $body-font;
    font-weight: 400;
    line-height: 1.5;
}

.footer-subheadline {
    font-size: 1.6rem;
    line-height: 1.2;
    letter-spacing: 0.1em;
    color: #1267B1;

    @include media-breakpoint-up(md) {
        font-size: 2rem;
    }


}

.highlight {
    color: #EE6C4D;
}

.notes {
    font-family: 'Sticky Notes';
    font-size: 3rem;
    line-height: 1;
    letter-spacing: 0.02em;
    color: #000000;
    
    @include media-breakpoint-up(md) {
        font-size: 3.6rem;
    }
}

.hands-on-font {
    font-weight: 700;
    font-size: 1.6rem;
    line-height: 1.15;
    letter-spacing: 0.02em;
    color: #FFFFFF;
    font-family: $body-font;

    @include media-breakpoint-up(md) {
        font-size: 2rem;
    }

}

.wht {
    color: #FFFFFF;
}

.lt-blue{
    color: rgba(3, 117, 156, 1);
}
.d-blue{
    color: rgba(3, 36, 76, 1);

}
